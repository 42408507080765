import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export const login = async (email: string, password: string) => {
  const auth = getAuth();
  let user = undefined;
  await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      user = userCredential;
    })
    .catch((e) => console.error(e));

  return user;
};
