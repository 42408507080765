/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { firebaseApp } from '..';

export type Ethnicities =
  | 'African'
  | 'African American'
  | 'Asian'
  | 'Caribbean'
  | 'White/Caucasian'
  | 'East Asian'
  | 'European'
  | 'Hispanic'
  | 'Indigenous'
  | 'Latinx'
  | 'Middle Eastern'
  | 'Multiracial'
  | 'Native American'
  | 'Pacific Islander'
  | 'South Asian'
  | 'Southeast Asian'
  | 'Other'
  | undefined;

export type UserType = {
  isBanned?: boolean;
  isOver18: boolean;
  creationTime: string;
  ageRange: number[];
  searchDistance: number[];
  firstName: string;
  email: string | undefined;
  astrologicalSign: string | undefined;
  location: Location;
  userIncludedSearches: string;
  isVerified: boolean;
  pictures: Array<string>;
  bio: string | undefined;
  gender: string;
  isProfileVisible: boolean;
  birthday: string;
  orientation: string;
  whoUserWantsToSeeOnFeed: string;
  uid: string;
  token: string;
  phonenumber: string;
  messageNotification: number;
  notifications: {
    swipeRights: boolean;
    messages: boolean;
    matches: boolean;
    reviews: boolean;
  };
  basicInfo: {
    ethnicity?: Ethnicities[] | undefined;
    homeTown?: string | undefined;
    height?: string | undefined;
    drinking?: 'Yes' | 'Sometimes' | 'No' | 'Socially' | undefined;
    smoking?: 'Yes' | 'Sometimes' | 'No' | 'Socially' | undefined;
    weed?: 'Yes' | 'Sometimes' | 'No' | 'Socially' | undefined;
    drugs?: 'Yes' | 'Sometimes' | 'No' | 'Socially' | undefined;
    job?: { title: string; companyOrIndustry: string };
    exercise?: 'Yes' | 'Sometimes' | 'No' | undefined;
    education?: { institution: string; graduateYear: string };
    kids?:
      | 'Have and want more'
      | 'Have and do not want more'
      | 'Do not want'
      | 'Not sure'
      | undefined;
    politics?:
      | 'Moderate'
      | 'Not political'
      | 'Liberal'
      | 'Conservative'
      | undefined;
    educationLevel?:
      | 'High School'
      | 'Trade school'
      | 'In college'
      | 'Undergraduate degree'
      | 'In graduate school'
      | 'Graduate Degree'
      | undefined;
    lookingFor?:
      | 'Marriage'
      | 'Long term relationship'
      | 'Short term'
      | 'Not sure'
      | undefined;
  };
};

export const AdminScreen = () => {
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [reportData, setReportData] = React.useState<string[]>([]);
  const [sortDirection, setSortDirection] = React.useState('asc');
  let usersArray: UserType[] = [];
  const db = getFirestore(firebaseApp);

  React.useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(collection(db, 'users'));

      data.forEach((doc) => {
        usersArray.push(doc.data() as UserType);
      });
      setUsers(usersArray);
    };
    getUsers();
  }, []);

  const auth = getAuth();
  const nav = useNavigate();
  const logout = () => {
    signOut(auth).then(() => nav('/login'));
  };

  const getReportNumbers = (uid: string) => {
    const docRef = doc(db, 'reports', uid);

    return getDoc(docRef)
      .then((docSnap) => {
        return docSnap.data()?.reports.length === undefined
          ? 'Null'
          : docSnap.data()?.reports.length;
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error getting report:', error);
        return 'error';
      });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const userPromises = users?.map(async (user) => {
          const reportNumber = await getReportNumbers(user.uid);
          return { ...user, reportNumber };
        });

        const updatedUsers = await Promise.all(userPromises);
        const reportNumbers = updatedUsers.map((user) => user.reportNumber);

        setReportData(reportNumbers);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchData();
  }, [users]);

  const handleSort = () => {
    const direction = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(direction);

    const sorted = [...users].sort((a, b) => {
      const reportNumberA = parseInt(reportData[users.indexOf(a)]);
      const reportNumberB = parseInt(reportData[users.indexOf(b)]);

      if (direction === 'asc') {
        return reportNumberA - reportNumberB;
      } else {
        return reportNumberB - reportNumberA;
      }
    });

    setUsers(sorted);
  };

  if (users?.length) {
    return (
      <>
        <div
          style={{
            height: '100vh',
            width: '100vw',
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <TableContainer
            style={{ width: '80%', height: '90%', alignSelf: 'center' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell onClick={handleSort}>
                    Report #'s{' '}
                    {sortDirection === 'asc' ? (
                      <span>&uarr;</span>
                    ) : (
                      <span>&darr;</span>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => {
                  return (
                    <TableRow
                      onClick={() => {
                        nav(`user/${user.uid}`);
                      }}
                      key={user.uid}
                    >
                      <TableCell>
                        {user.firstName ? user.firstName : 'Null'}
                      </TableCell>
                      <TableCell>{user.uid}</TableCell>
                      <TableCell>{reportData[index]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  }
  return (
    <>
      <Button onClick={() => logout()}>Logout</Button>
    </>
  );
};
