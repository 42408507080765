import React from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export const DetectScreen = () => {
  const [device, setDevice] = React.useState<string | null>(null);
  React.useEffect(() => {
    const detectDevice = httpsCallable(getFunctions(), "detectDeviceV2");
    detectDevice()
      .then((result: any) => {
        if (result.data.url) {
          // Redirect to the appropriate store URL based on the result from Firebase
          window.location.href = result.data.url;
        } else {
          console.log(result.data.url);
          alert("Device not recognized or other issue.");
          setDevice("Computer");
        }
      })
      .catch((error: any) => {
        console.error("Error detecting device:", error);
      });
  }, []);
  if (device) {
    return (
      <div>
        <h1>Can not go to app store link from a desktop computer </h1>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Detecting your device...</h1>
      </div>
    );
  }
};
