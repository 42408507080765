/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

export const NavHeader = styled.div`
  width: 100vw;
  height: 8%;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`;

export const NavItems = styled.nav`
  height: 5vh;

  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 10%;
  margin-left: auto;
`;

export const NavTitle = styled(Link)`
  text-decoration: none;
  font-size: larger;
  font-weight: bolder;
  color: #121212;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledNavLink = styled(Link)`
  text-decoration: none;
  color: #121212;
  font-family: Roboto;
  font-size: 24px;
`;

export const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledNavLink to={to}>{children}</StyledNavLink>
    </div>
  );
};
