import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            justifyContent: "center",
            marginTop: "10%",
            marginBottom: "10%",
            width: "80%",
            display: "flex",

            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ textAlign: "center" }}>
            Privacy Policy for Face2FaceDatesDating App
          </h1>
          <p>
            At Face2FaceDates, we are committed to protecting the privacy and
            security of our users. This Privacy Policy outlines the types of
            personal information we collect, how we use and share it, and the
            measures we take to safeguard your data while using our dating
            application. By accessing and using the Face2FaceDates app, you
            agree to the terms outlined in this Privacy Policy. If you do not
            agree with these terms, please refrain from using the app.
          </p>

          <h2>1. Information Collection and Use</h2>

          <p>
            <strong>1.1. Personal Information:</strong> To provide our services
            and enhance your experience on the app, we may collect personal
            information, such as your name, email address, gender, date of
            birth, location, and photographs. This information is used to create
            and manage your profile, enable communication with other users, and
            personalize your experience on the app.
          </p>

          <p>
            <strong>1.2. Communication Data:</strong> We may collect information
            about your interactions and communications with other users on
            Face2FaceDates, including messages, photos shared, and other content
            you exchange within the app.
          </p>

          <p>
            <strong>1.3. Usage Information:</strong> Face2FaceDates may collect
            data about your usage of the app, including log information, device
            information, IP address, and browsing activities. This data is used
            for analytics, troubleshooting, and improving our app's
            functionality.
          </p>

          <p>
            <strong>1.4. Payment Information:</strong> If you make in-app
            purchases, our third-party payment processors may collect and
            process your payment information. We do not store your payment
            details on our servers.
          </p>

          <h2>2. Data Sharing and Disclosure</h2>

          <p>
            <strong>2.1. User Interactions:</strong> When you engage in
            communication with other users on Face2FaceDates, certain personal
            information, like your username and profile picture, may be visible
            to them. However, we will never share your contact information
            without your explicit consent.
          </p>

          <p>
            <strong>2.2. Service Providers:</strong> We may engage third-party
            service providers to assist us in providing our services, including
            customer support, analytics, and payment processing. These service
            providers have access to your personal information only to perform
            specific tasks on our behalf and are bound by confidentiality
            obligations.
          </p>

          <p>
            <strong>2.3. Legal Compliance:</strong> We may share your personal
            information when required by law, legal process, or government
            request, or to protect our rights, privacy, safety, or property, or
            those of our users.
          </p>

          <h2>3. Cookies and Similar Technologies</h2>

          <p>
            <strong>3.1. Cookies:</strong> Face2FaceDates may use cookies and
            similar tracking technologies to collect information about your
            preferences, app usage patterns, and to remember your settings. You
            can manage your cookie preferences through your device or app
            settings.
          </p>

          <h2>4. Security</h2>

          <p>
            <strong>4.1. Data Security:</strong> We implement industry-standard
            security measures to protect your personal information from
            unauthorized access, disclosure, alteration, or destruction.
            However, no method of transmission over the internet or electronic
            storage is entirely secure, and we cannot guarantee absolute
            security.
          </p>

          <h2>5. Your Choices</h2>

          <p>
            <strong>5.1. Account Settings:</strong> You can review and update
            your personal information in your account settings. You may also
            delete your account at any time, which will remove your profile and
            associated data.
          </p>

          <p>
            <strong>5.2. Marketing Communications:</strong> You can choose to
            opt-out of receiving promotional emails or push notifications by
            adjusting your settings within the app.
          </p>

          <h2>6. Children's Privacy</h2>

          <p>
            Face2FaceDates is not intended for users under the age of 18. We do
            not knowingly collect personal information from individuals under 18
            years of age. If we become aware that a user is underage, we will
            take appropriate steps to remove their information from our servers.
          </p>

          <h2>7. Updates to this Policy</h2>

          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We will notify you of any material changes by
            posting the updated policy on our website or within the app.
          </p>
          <h2>8. How long we retain your data </h2>
          <p>
            We want the connections you make through our service to last
            forever, but we keep your personal data only as long as necessary
            for legitimate business reasons and as permitted by applicable law.
            If you decide to stop using our service, you can close your account
            and your profile will no longer be visible to other members. Note
            that we will close your account automatically if you are inactive
            for two years. Following account closure, we delete your data as
            laid out below:
          </p>
          <p>
            <strong>8.1. Data retention time:</strong> To protect the safety and
            security of our members, we implement a safety retention window of
            three months following account closure or up to two years following
            an account ban. During this period, we keep your data to investigate
            unlawful or harmful conducts. The retention of data during this
            safety retention window is based on our legitimate interest as well
            as that of potential third-party victims.
          </p>
          <p>
            <strong>8.2. Legal Data Retention Policy: </strong>
            Finally, we maintain data on the basis of our legitimate interest
            where there is an outstanding or potential issue, claim or dispute
            requiring us to keep data, in particular if we receive a valid legal
            subpoena or request asking us to preserve data (in which case we
            would need to keep the data to comply with our legal obligations) or
            if data would otherwise be necessary as part of legal proceedings.
          </p>
          <p>
            If you have any questions or concerns regarding this Privacy Policy
            or our data practices, please contact us at
            <Link
              onClick={() =>
                (window.location.href = "mailto:face2facedates.sup@gmail.com")
              }
              to="javascript:void(0)"
            >
              {" "}
              face2facedates.sup@gmail.com{"   "}
            </Link>
          </p>

          <p>
            By using the Face2FaceDates app, you acknowledge that you have read
            and understood this Privacy Policy and agree to be bound by its
            terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
