import React from "react";

export const TestimonialsScreen = () => {
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  React.useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  if (matches) {
    return (
      <>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            height: "100%",
          }}
        >
          <div
            style={{
              marginTop: "10%",
              alignSelf: "center",
              width: "90%",
              display: "flex",
              height: "auto",
            }}
          >
            <h1 style={{ fontFamily: "Roboto" }}>
              Here are some of our stories
            </h1>
          </div>
          <div
            style={{
              flexDirection: "row",
              width: "90%",
              display: "flex",
              height: "auto",
              alignSelf: "center",
              marginBottom: "10%",
            }}
          >
            <div
              style={{
                width: "40%",
                padding: "2rem",
                borderWidth: 1,
                height: "60%",
                borderColor: "#7851a9",
                borderStyle: "solid",
                backgroundColor: "#7851a9",
                borderRadius: "5%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <img
                alt="Stephen & Taylor testimonial"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  height: "100%",
                  width: "100%",
                }}
                src={"images/stephen&taylor.png"}
              />
            </div>
            <div
              style={{
                marginLeft: "3%",
                display: "flex",
                width: "50%",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  fontFamily: "Roboto-Bold",

                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Stephen & Taylor
              </h2>
              <div
                style={{
                  width: "80%",
                  fontFamily: "Roboto",
                  lineHeight: 1.5,

                  fontSize: "20px",
                  flexWrap: "wrap",
                }}
              >
                <p>
                  Face2FaceDates, a dating app that changed the game for me. I'm
                  Stephen, a busy banker who was skeptical about online dating.
                  But when I swiped right and met Taylor, it felt like destiny
                  had intervened. Our first video call on Face2FaceDates was
                  electrifying, an instant connection that had us talking like
                  old friends. Within 24 hours, we had shared our love for
                  travel and a mutual thirst for adventure. Face2FaceDates made
                  every call special, as if distance didn't exist between us.
                  Days turned into weeks, and we found ourselves longing to see
                  each other's smiles and hear each other's laughter every
                  single day. Thanks to Face2FaceDates, I found not just a
                  perfect match, but a partner who understood and embraced my
                  busy life as a banker. Taylor's adventurous spirit inspired me
                  to break free from routine and explore the world with renewed
                  passion. So, if you're hesitant about dating apps, give
                  Face2FaceDates a try. You might just find your own special
                  connection.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              width: "90%",
              display: "flex",
              height: "auto",
              alignSelf: "center",
              marginBottom: "10%",
            }}
          >
            <div
              style={{
                width: "40%",
                padding: "2rem",
                borderWidth: 1,
                height: "60%",
                borderColor: "#7851a9",
                borderStyle: "solid",
                backgroundColor: "#7851a9",
                borderRadius: "5%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <img
                alt="Terrant & Saphia testimonial"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  height: "100%",
                  width: "100%",
                }}
                src={"images/terrant&saphia.png"}
              />
            </div>
            <div
              style={{
                marginLeft: "3%",
                display: "flex",
                width: "50%",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  fontFamily: "Roboto-Bold",

                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Terrant & Saphia
              </h2>
              <div
                style={{
                  width: "80%",
                  fontFamily: "Roboto",
                  lineHeight: 1.5,
                  fontSize: "20px",
                  flexWrap: "wrap",
                }}
              >
                <p>
                  As I swiped through profiles on "Face2FaceDates," one person
                  stood out among the rest - Saphia. Her captivating smile and
                  vibrant personality caught my attention instantly, and I knew
                  I had to get to know her. Our first video call was both
                  exciting and nerve-wracking, but Saphia's warmth and charm
                  quickly put me at ease. We chatted like old friends, and I
                  found myself sharing my love for my curly hair and the
                  challenges that came with it. To my surprise, Saphia not only
                  understood my curly hair struggles but also offered some
                  helpful tips and encouragement. It was as if she already knew
                  how to make me feel comfortable and understood. As our
                  conversations continued, I realized that we had more in common
                  than I could have imagined. Our shared interests, values, and
                  sense of humor made every call feel like a delightful
                  adventure. When we finally met in person, it was as if we had
                  known each other forever. Saphia's presence was comforting,
                  and her smile lit up the room. I couldn't help but feel that
                  this connection was something special. As our relationship
                  grew, I found myself falling in love with Saphia's kindness,
                  her passion for life, and the way she made me feel cherished.
                  With her by my side, I knew I had found someone truly
                  remarkable. Thanks to "Face2FaceDates," I found not only a
                  perfect match but also a partner who embraced every aspect of
                  who I am, including my curly hair. Together, we embarked on a
                  beautiful journey of love, laughter, and shared adventures. In
                  a world of digital connections, ours was a love story that
                  transcended the virtual realm and became a cherished reality.
                  I am grateful for the day I swiped right on Saphia, as it led
                  me to the love of my life and a relationship filled with
                  happiness and joy.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              flexDirection: "row",
              width: "90%",
              display: "flex",
              height: "auto",
              alignSelf: "center",
              marginBottom: "10%",
            }}
          >
            <div
              style={{
                width: "40%",
                padding: "2rem",
                borderWidth: 1,
                height: "60%",
                borderColor: "#7851a9",
                borderStyle: "solid",
                backgroundColor: "#7851a9",
                borderRadius: "5%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <img
                alt="Trent & Kait testimonial"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  height: "100%",
                  width: "100%",
                }}
                src={"images/kaitlmao.png"}
              />
            </div>
            <div
              style={{
                marginLeft: "3%",
                display: "flex",
                width: "50%",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  fontFamily: "Roboto-Bold",

                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Trent & Kaitlyn
              </h2>
              <div
                style={{
                  width: "80%",
                  fontFamily: "Roboto",
                  lineHeight: 1.5,
                  fontSize: "20px",
                  flexWrap: "wrap",
                }}
              >
                <p>
                  Tired of the monotonous routine of traditional dating apps, I
                  craved a more intimate and personal connection, one that
                  transcended the endless cycle of messaging and ghosting.
                  Determined to find a solution, I embarked on a mission to
                  create a platform that would revolutionize the dating
                  experience. Thus, "Face2FaceDates" was born. As the founder of
                  "Face2FaceDates," I poured my heart and soul into crafting an
                  app that would enable genuine connections through video calls.
                  Little did I know that my own love story would be written
                  within the very code I designed. Amidst the sea of profiles,
                  one name stood out - Kait. Her radiant smile and warm eyes
                  immediately caught my attention, and I knew I had to take a
                  chance. With eager anticipation, I swiped right, hoping for
                  the serendipity that fate often bestows. To my delight, we
                  matched, and my heart skipped a beat as we connected through a
                  video call. In that moment, the screen dissolved, and it felt
                  as if we were in the same room, gazing into each other's
                  souls. Kait's presence was enchanting, and we effortlessly
                  delved into conversation, our words flowing freely as if we
                  were long-lost friends reunited. It was an instant connection
                  - that rare feeling when you know you've found someone
                  special. Through "Face2FaceDates," we transcended the mundane
                  texts and superficiality of other apps. Video calls enabled us
                  to truly see each other, to feel the authenticity of our
                  emotions, and to cherish the nuances that make us who we are.
                  With every call, our bond deepened. We shared our dreams, our
                  passions, and our vulnerabilities. It was a dance of souls,
                  each step bringing us closer to a love that felt both destined
                  and magical. As our love story unfolded, we celebrated the
                  beauty of our unconventional meeting, a testament to the power
                  of technology and the human heart. Kait, the beautiful soul I
                  found on "Face2FaceDates," became not only the love of my life
                  but also a muse that inspired the very essence of the app
                  itself. In the end, I discovered that love could indeed
                  blossom within the confines of a dating app. "Face2FaceDates"
                  wasn't just my creation; it became the conduit through which
                  two hearts found solace, authenticity, and a connection that
                  was nothing short of extraordinary.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            height: "100%",
            marginTop: "10%",
          }}
        >
          <div
            style={{
              alignSelf: "center",
              width: "90%",
              display: "flex",
              height: "auto",
            }}
          >
            <h2 style={{ fontFamily: "Roboto" }}>
              Here are some of our stories
            </h2>
          </div>
          <div
            style={{
              flexDirection: "column",
              width: "90%",
              display: "flex",
              height: "auto",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                padding: "2rem",
                borderWidth: 1,
                height: "60%",
                borderColor: "#7851a9",
                borderStyle: "solid",
                backgroundColor: "#7851a9",
                borderRadius: "5%",
                alignItems: "center",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <img
                alt="Stephen & Taylor testimonial"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  height: "100%",
                  width: "100%",
                }}
                src={"images/stephen&taylor.png"}
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                display: "flex",
                alignSelf: "center",
                width: "90%",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto-Bold",

                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Stephen & Taylor
              </div>
              <div
                style={{
                  width: "80%",
                  fontFamily: "Roboto",
                  lineHeight: 1.5,
                  fontSize: "20px",
                  flexWrap: "wrap",
                }}
              >
                <p>
                  Face2FaceDates, a dating app that changed the game for me. I'm
                  Stephen, a busy banker who was skeptical about online dating.
                  But when I swiped right and met Taylor, it felt like destiny
                  had intervened. Our first video call on Face2FaceDates was
                  electrifying, an instant connection that had us talking like
                  old friends. Within 24 hours, we had shared our love for
                  travel and a mutual thirst for adventure. Face2FaceDates made
                  every call special, as if distance didn't exist between us.
                  Days turned into weeks, and we found ourselves longing to see
                  each other's smiles and hear each other's laughter every
                  single day. Thanks to Face2FaceDates, I found not just a
                  perfect match, but a partner who understood and embraced my
                  busy life as a banker. Taylor's adventurous spirit inspired me
                  to break free from routine and explore the world with renewed
                  passion. So, if you're hesitant about dating apps, give
                  Face2FaceDates a try. You might just find your own special
                  connection.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "10%",
              flexDirection: "column",
              width: "90%",
              display: "flex",
              height: "100%",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "90%",
                padding: "2rem",
                borderWidth: 1,
                height: "40%",
                borderColor: "#7851a9",
                borderStyle: "solid",
                backgroundColor: "#7851a9",
                borderRadius: "5%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <img
                alt="Terrant & Saphia testimonial"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  height: "100%",
                  width: "100%",
                }}
                src={"images/terrant&saphia.png"}
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                display: "flex",
                alignSelf: "center",
                width: "90%",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto-Bold",
                  alignSelf: "center",
                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Terrant & Saphia
              </div>
              <div
                style={{
                  width: "90%",
                  fontFamily: "Roboto",
                  lineHeight: 1.5,
                  fontSize: "20px",
                }}
              >
                <p style={{ marginBottom: "100px" }}>
                  As I swiped through profiles on "Face2FaceDates," one person
                  stood out among the rest - Saphia. Her captivating smile and
                  vibrant personality caught my attention instantly, and I knew
                  I had to get to know her. Our first video call was both
                  exciting and nerve-wracking, but Saphia's warmth and charm
                  quickly put me at ease. We chatted like old friends, and I
                  found myself sharing my love for my curly hair and the
                  challenges that came with it. To my surprise, Saphia not only
                  understood my curly hair struggles but also offered some
                  helpful tips and encouragement. It was as if she already knew
                  how to make me feel comfortable and understood. As our
                  conversations continued, I realized that we had more in common
                  than I could have imagined. Our shared interests, values, and
                  sense of humor made every call feel like a delightful
                  adventure. When we finally met in person, it was as if we had
                  known each other forever. Saphia's presence was comforting,
                  and her smile lit up the room. I couldn't help but feel that
                  this connection was something special. As our relationship
                  grew, I found myself falling in love with Saphia's kindness,
                  her passion for life, and the way she made me feel cherished.
                  With her by my side, I knew I had found someone truly
                  remarkable. Thanks to "Face2FaceDates," I found not only a
                  perfect match but also a partner who embraced every aspect of
                  who I am, including my curly hair. Together, we embarked on a
                  beautiful journey of love, laughter, and shared adventures. In
                  a world of digital connections, ours was a love story that
                  transcended the virtual realm and became a cherished reality.
                  I am grateful for the day I swiped right on Saphia, as it led
                  me to the love of my life and a relationship filled with
                  happiness and joy.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              flexDirection: "column",
              width: "90%",
              display: "flex",
              height: "100%",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                width: "90%",
                padding: "2rem",
                borderWidth: 1,
                height: "70%",
                borderColor: "#7851a9",
                borderStyle: "solid",
                backgroundColor: "#7851a9",
                borderRadius: "5%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <img
                alt="Trent & Kait testimonial"
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  height: "100%",
                  width: "100%",
                }}
                src={"images/kaitlmao.png"}
              />
            </div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                display: "flex",
                alignSelf: "center",
                width: "90%",
                height: "50%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto-Bold",
                  alignSelf: "center",
                  fontWeight: "bold",
                  fontSize: "40px",
                }}
              >
                Trent & Kait
              </div>
              <div
                style={{
                  width: "90%",
                  fontFamily: "Roboto",
                  lineHeight: 1.5,
                  fontSize: "20px",
                  marginBottom: "100px",
                }}
              >
                <p style={{ marginBottom: "100px" }}>
                  Tired of the monotonous routine of traditional dating apps, I
                  craved a more intimate and personal connection, one that
                  transcended the endless cycle of messaging and ghosting.
                  Determined to find a solution, I embarked on a mission to
                  create a platform that would revolutionize the dating
                  experience. Thus, "Face2FaceDates" was born. As the founder of
                  "Face2FaceDates," I poured my heart and soul into crafting an
                  app that would enable genuine connections through video calls.
                  Little did I know that my own love story would be written
                  within the very code I designed. Amidst the sea of profiles,
                  one name stood out - Kait. Her radiant smile and warm eyes
                  immediately caught my attention, and I knew I had to take a
                  chance. With eager anticipation, I swiped right, hoping for
                  the serendipity that fate often bestows. To my delight, we
                  matched, and my heart skipped a beat as we connected through a
                  video call. In that moment, the screen dissolved, and it felt
                  as if we were in the same room, gazing into each other's
                  souls. Kait's presence was enchanting, and we effortlessly
                  delved into conversation, our words flowing freely as if we
                  were long-lost friends reunited. It was an instant connection
                  - that rare feeling when you know you've found someone
                  special. Through "Face2FaceDates," we transcended the mundane
                  texts and superficiality of other apps. Video calls enabled us
                  to truly see each other, to feel the authenticity of our
                  emotions, and to cherish the nuances that make us who we are.
                  With every call, our bond deepened. We shared our dreams, our
                  passions, and our vulnerabilities. It was a dance of souls,
                  each step bringing us closer to a love that felt both destined
                  and magical. As our love story unfolded, we celebrated the
                  beauty of our unconventional meeting, a testament to the power
                  of technology and the human heart. Kait, the beautiful soul I
                  found on "Face2FaceDates," became not only the love of my life
                  but also a muse that inspired the very essence of the app
                  itself. In the end, I discovered that love could indeed
                  blossom within the confines of a dating app. "Face2FaceDates"
                  wasn't just my creation; it became the conduit through which
                  two hearts found solace, authenticity, and a connection that
                  was nothing short of extraordinary.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
